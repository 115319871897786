import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { apiCallBegan } from './api'

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loading: false,
  },
  reducers: {
    authRequested: auth => {
      auth.loading = true
    },
    authRequestEnded: auth => {
      auth.loading = false
    },
    authSucceeded: (auth, action) => {
      auth.user = action.payload.data
    },
    logoutSucceeded: auth => {
      auth.user = null
      document.location.href = '/login'
    },
  },
})

export const { authRequested, authRequestEnded, authSucceeded, logoutSucceeded } = slice.actions
export default slice.reducer

//api calls
export const login = credentials =>
  apiCallBegan({
    url: '/auth/login',
    method: 'post',
    data: credentials,
    onStart: authRequested,
    onSuccess: authSucceeded,
    onEnd: authRequestEnded,
  })

export const logout = () =>
  apiCallBegan({
    url: '/auth/logout',
    method: 'post',
    onStart: authRequested,
    onSuccess: logoutSucceeded,
    onEnd: authRequestEnded,
  })

export const getMe = () =>
  apiCallBegan({
    url: '/users/me',
    onStart: authRequested,
    onSuccess: authSucceeded,
    onEnd: authRequestEnded,
  })

//selector
export const selectAuth = createSelector(
  state => state.auth,
  auth => auth,
  {
    devModeChecks: { identityFunctionCheck: 'never' },
  },
)
