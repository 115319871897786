import { useContext, useMemo } from 'react'
import { alpha, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { HambergerMenu } from 'iconsax-react'
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH, ThemeMode } from 'config'
import useConfig from 'hooks/useConfig'
import IconButton from 'components/@extended/IconButton'
import { Context as LayoutContext } from '../Provider'
import Content from './Content'
import * as Styled from './index.styles'

const Header = () => {
  const theme = useTheme()
  const downLG = useMediaQuery(theme.breakpoints.down('lg'))
  const { mode } = useConfig()
  const { drawerOpen, toggleDrawer } = useContext(LayoutContext)

  const iconBackColorOpen = mode === ThemeMode.DARK ? 'background.paper' : 'secondary.200'
  const iconBackColor = mode === ThemeMode.DARK ? 'background.default' : 'secondary.100'

  const content = (
    <Toolbar sx={{ px: { xs: 2, sm: 4.5, lg: 8 } }}>
      <IconButton
        aria-label="open drawer"
        onClick={toggleDrawer}
        edge="start"
        color="secondary"
        variant="light"
        size="large"
        sx={{
          color: 'secondary.main',
          bgcolor: drawerOpen ? iconBackColorOpen : iconBackColor,
          ml: { xs: 0, lg: -2 },
          p: 1,
        }}
      >
        <HambergerMenu />
      </IconButton>
      <Content />
    </Toolbar>
  )

  // app-bar params
  const appBarProps = useMemo(
    () => ({
      position: 'fixed',
      elevation: 0,
      sx: {
        bgcolor: alpha(theme.palette.background.default, 0.8),
        backdropFilter: 'blur(8px)',
        zIndex: 1200,
        width: {
          xs: '100%',
          lg: drawerOpen ? `calc(100% - ${DRAWER_WIDTH}px)` : `calc(100% - ${MINI_DRAWER_WIDTH}px)`,
        },
      },
    }),
    [drawerOpen, theme],
  )

  // noinspection JSValidateTypes
  return downLG ? (
    <AppBar {...appBarProps}>{content}</AppBar>
  ) : (
    <Styled.AppBar open={drawerOpen} {...appBarProps}>
      {content}
    </Styled.AppBar>
  )
}

export default Header
