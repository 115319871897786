import { alpha, styled } from '@mui/material/styles'
import MuiIconButton from '@mui/material/IconButton'
import { getColors, getShadow } from 'theme/utils'

const getColorStyle = ({ variant, theme, color }) => {
  const colors = getColors(theme, color)
  const { lighter, light, dark, main, contrastText } = colors

  const buttonShadow = `${color}Button`
  const shadows = getShadow(theme, buttonShadow)

  const common = {
    '&::after': {
      boxShadow: `0 0 6px 6px ${alpha(main, 0.9)}`,
    },
    '&:active::after': {
      boxShadow: `0 0 0 0 ${alpha(main, 0.9)}`,
    },
    '&:focus-visible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2,
    },
  }

  switch (variant) {
    case 'contained':
      return {
        color: contrastText,
        backgroundColor: main,
        '&:hover': {
          backgroundColor: dark,
        },
        ...common,
      }
    case 'light':
      return {
        color: main,
        backgroundColor: lighter,
        '&:hover': {
          backgroundColor: light,
        },
        ...common,
      }
    case 'shadow':
      return {
        boxShadow: shadows,
        color: contrastText,
        backgroundColor: main,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: dark,
        },
        ...common,
      }
    case 'outlined':
      return {
        '&:hover': {
          backgroundColor: 'transparent',
          color: dark,
          borderColor: dark,
        },
        ...common,
      }
    case 'dashed':
      return {
        backgroundColor: lighter,
        '&:hover': {
          color: dark,
          borderColor: dark,
        },
        ...common,
      }
    case 'text':
    default:
      return {
        '&:hover': {
          color: dark,
          backgroundColor: lighter,
        },
        ...common,
      }
  }
}

export const IconButton = styled(MuiIconButton, {
  shouldForwardProp: prop => prop !== 'variant' && prop !== 'shape',
})(({ theme, variant, shape, color }) => ({
  position: 'relative',
  '::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    borderRadius: shape === 'rounded' ? '50%' : 8,
    opacity: 0,
    transition: 'all 0.5s',
  },

  ':active::after': {
    position: 'absolute',
    borderRadius: shape === 'rounded' ? '50%' : 8,
    left: 0,
    top: 0,
    opacity: 1,
    transition: '0s',
  },
  ...(shape === 'rounded' && {
    borderRadius: '50%',
  }),
  ...(variant === 'outlined' && {
    border: '1px solid',
    borderColor: 'inherit',
  }),
  ...(variant === 'dashed' && {
    border: '1px dashed',
    borderColor: 'inherit',
  }),
  ...(variant !== 'text' && {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary[200],
    },
  }),
  ...getColorStyle({ variant, theme, color }),
}))
