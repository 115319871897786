import axios from 'axios'
import { apiCallBegan, apiCallFailed, apiCallSuccess } from '../api'

export const api =
  ({ dispatch }) =>
  next =>
  async action => {
    if (action.type !== apiCallBegan.type) return next(action)

    const {
      url,
      headers = {},
      method = 'get',
      params,
      data,
      onStart,
      onSuccess,
      onError,
      onEnd,
    } = action.payload

    if (onStart) dispatch(onStart())

    next(action)

    try {
      const { data: result } = await axios.request({
        baseURL: process.env.REACT_APP_BASE_URL,
        url,
        method,
        params,
        data,
        headers,
        withCredentials: true,
      })

      dispatch(apiCallSuccess(result))

      if (onSuccess) {
        dispatch(onSuccess(result))
      }

      return result
    } catch (e) {
      if (e.response) {
        dispatch(apiCallFailed(e))
      }

      if (onError) {
        dispatch(onError(e))
      }

      throw e
    } finally {
      if (onEnd) {
        dispatch(onEnd())
      }
    }
  }

export default api
