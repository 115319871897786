import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Grow from '@mui/material/Grow'
import Slide from '@mui/material/Slide'
import Zoom from '@mui/material/Zoom'

// ==============================|| TRANSITIONS ||============================== //

const Transitions = (
  { children, position = 'top-left', type = 'grow', direction = 'up', ...others },
  ref,
) => {
  let positionSX

  switch (position) {
    case 'top-right':
      positionSX = {
        transformOrigin: 'top right',
      }
      break
    case 'top':
      positionSX = {
        transformOrigin: 'top',
      }
      break
    case 'bottom-left':
      positionSX = {
        transformOrigin: 'bottom left',
      }
      break
    case 'bottom-right':
      positionSX = {
        transformOrigin: 'bottom right',
      }
      break
    case 'bottom':
      positionSX = {
        transformOrigin: 'bottom',
      }
      break
    case 'top-left':
    default:
      positionSX = {
        transformOrigin: '0 0 0',
      }
      break
  }

  return (
    <Box ref={ref}>
      {type === 'grow' && (
        <Grow
          {...others}
          timeout={{
            appear: 0,
            enter: 150,
            exit: 150,
          }}
        >
          <Box sx={positionSX}>{children}</Box>
        </Grow>
      )}

      {type === 'collapse' && (
        <Collapse {...others} sx={positionSX}>
          {children}
        </Collapse>
      )}

      {type === 'fade' && (
        <Fade
          {...others}
          timeout={{
            appear: 0,
            enter: 300,
            exit: 150,
          }}
        >
          <Box sx={positionSX}>{children}</Box>
        </Fade>
      )}

      {type === 'slide' && (
        <Slide
          {...others}
          timeout={{
            appear: 0,
            enter: 150,
            exit: 150,
          }}
          direction={direction}
        >
          <Box sx={positionSX}>{children}</Box>
        </Slide>
      )}

      {type === 'zoom' && (
        <Zoom {...others}>
          <Box sx={positionSX}>{children}</Box>
        </Zoom>
      )}
    </Box>
  )
}

export default forwardRef(Transitions)

// ==============================|| POPUP / DIALOG - TRANSITIONS ||============================== //

// noinspection JSValidateTypes
export const PopupTransition = forwardRef(({ children, ...rest }, ref) => (
  <Zoom ref={ref} timeout={200} {...rest}>
    {children}
  </Zoom>
))

Transitions.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string,
  type: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'right', 'left', 'down']),
  others: PropTypes.any,
}
