import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { getColors } from 'theme/utils'

const Dot = ({ color, size, variant, sx, componentDiv }) => {
  const theme = useTheme()
  const colors = getColors(theme, color || 'primary')
  const { main } = colors

  return (
    <Box
      component={componentDiv ? 'div' : 'span'}
      sx={{
        width: size || 8,
        height: size || 8,
        borderRadius: '50%',
        bgcolor: variant === 'outlined' ? '' : main,
        ...(variant === 'outlined' && { border: `1px solid `, borderColor: main }),
        ...sx,
      }}
    />
  )
}

Dot.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  variant: PropTypes.string,
  sx: PropTypes.any,
  componentDiv: PropTypes.bool,
}

export default Dot
