import { styled } from '@mui/material/styles'

export const Loader = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': { marginTop: theme.spacing(2) },
}))
