import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Logo from 'components/Logo'
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'config'
import * as Styled from './index.styles'

const Header = ({ open }) => {
  const theme = useTheme()
  const downLG = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Styled.Header
      open={open}
      sx={{
        minHeight: downLG ? 'unset' : HEADER_HEIGHT,
        width: downLG ? { xs: '100%', lg: DRAWER_WIDTH + 50 } : 'inherit',
        paddingTop: downLG ? { xs: '10px', lg: '0' } : '8px',
        paddingBottom: downLG ? { xs: '18px', lg: '0' } : '8px',
        paddingLeft: downLG ? { xs: '24px', lg: '0' } : open ? '24px' : 0,
      }}
    >
      <Logo isIcon={!open} sx={{ width: open ? 'auto' : 52, height: 'auto' }} />
    </Styled.Header>
  )
}

Header.propTypes = { open: PropTypes.bool }

export default Header
