// ==============================|| OVERRIDES - BODY ||============================== //

export default function Body() {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFeatureSettings: '"salt"',
        },
      },
    },
  }
}
