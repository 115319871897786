import { matchPath } from 'react-router-dom'

export const isActive = (item, pathname) => {
  const path = item.link || item.url

  const isCurrentUrl = path ? !!matchPath({ path, end: false }, pathname) : false

  if (isCurrentUrl) {
    return true
  } else if (item.children?.length > 0) {
    return item.children.some(child => isActive(child, pathname))
  }

  return false
}
