import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'
import { isActive } from './utils'

const Context = createContext({
  selectedId: undefined,
  setSelectedId: console.log,
})

const Provider = ({ children }) => {
  const [selectedId, setSelectedId] = useState(undefined)
  const [selectedLevel, setSelectedLevel] = useState(undefined)

  const value = useMemo(
    () => ({
      selectedId,
      setSelectedId,
      selectedLevel,
      setSelectedLevel,
    }),
    [selectedId],
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

Provider.propTypes = {
  children: PropTypes.node,
}

export const useNav = item => {
  const { pathname } = useLocation()
  const { setSelectedId, ...rest } = useContext(Context)

  const isSelected = useMemo(() => isActive(item, pathname), [item, pathname])

  useEffect(() => {
    if (isSelected) {
      setSelectedId(item.id)
    }
  }, [item, isSelected])

  return { isSelected, ...rest }
}

export default Provider
