import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

export const ExpandMore = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'theme' && prop !== 'expand' && prop !== 'drawerOpen',
})(({ theme, expand, drawerOpen }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(-90deg)',
  marginLeft: 'auto',
  color: theme.palette.secondary.dark,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  ...(!drawerOpen && {
    opacity: 0,
    width: 50,
    height: 50,
  }),
}))
