import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import IconButton from 'components/@extended/IconButton'
import { ThemeMode } from 'config'
import useConfig from 'hooks/useConfig'

const NAV_ACTION_TYPE = {
  FUNCTION: 'function',
  LINK: 'link',
}

const Action = ({ action, isSelected = false }) => {
  const theme = useTheme()
  const { mode } = useConfig()
  const ActionIcon = action.icon
  const callback = action.function

  return (
    <IconButton
      {...(action.type === NAV_ACTION_TYPE.FUNCTION && {
        onClick: event => {
          event.stopPropagation()
          callback?.()
        },
      })}
      {...(action.type === NAV_ACTION_TYPE.LINK && {
        component: Link,
        to: action.url,
        target: action.target ? '_blank' : '_self',
      })}
      color="secondary"
      variant="outlined"
      sx={{
        position: 'absolute',
        top: 12,
        right: 20,
        zIndex: 1202,
        width: 20,
        height: 20,
        p: 0.25,
        color: 'secondary.dark',
        borderColor: isSelected ? 'primary.light' : 'secondary.light',
        '&:hover': { borderColor: isSelected ? 'primary.main' : 'secondary.main' },
      }}
    >
      <ActionIcon
        size={12}
        color={
          mode === ThemeMode.DARK ? theme.palette.secondary[400] : theme.palette.secondary.main
        }
        style={{ marginLeft: 1 }}
      />
    </IconButton>
  )
}

Action.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.oneOf(['function', 'link']),
    icon: PropTypes.func.isRequired,
    function: PropTypes.func,
    url: PropTypes.string,
    target: PropTypes.bool,
  }).isRequired,
  isSelected: PropTypes.bool,
}

export default Action
