import { useContext } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import SimpleBar from 'components/SimpleBar'
import { Context as LayoutContext } from '../../Provider'
import NavUser from './NavUser'
import NavCard from './NavCard'
import Navigation from './Navigation'

const Content = () => {
  const theme = useTheme()
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))
  const { drawerOpen } = useContext(LayoutContext)

  return (
    <>
      <SimpleBar sx={{ '& .simplebar-content': { display: 'flex', flexDirection: 'column' } }}>
        <Navigation />
        {drawerOpen && !matchDownMD && <NavCard />}
      </SimpleBar>
      <NavUser />
    </>
  )
}

export default Content
