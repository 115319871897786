import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import loadable from 'utils/loadable'
import GuestGuard from 'guards/GuestGuard'
import AuthGuard from 'guards/AuthGuard'
import Layout from 'layout'

const LoginPage = loadable(lazy(() => import('pages/Login')))
// const HomePage = loadable(lazy(() => import('pages/Home')))

const router = createBrowserRouter([
  {
    path: '/login',
    element: (
      <GuestGuard>
        <LoginPage />
      </GuestGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    // children: [{ path: '/', element: <HomePage /> }],
  },
])

export default router
