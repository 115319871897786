// import Button from '@mui/material/Button'
// import Link from '@mui/material/Link'
// import CardMedia from '@mui/material/CardMedia'
// import Stack from '@mui/material/Stack'
// import Typography from '@mui/material/Typography'
import Card from 'components/Card'
// import avatar from 'assets/images/users/vector-2.png'
// import AnimateButton from 'components/@extended/AnimateButton'

// noinspection JSValidateTypes
const NavCard = () => (
  <Card sx={{ bgcolor: 'secondary.lighter', m: 3 }}>
    {/*<Stack alignItems="center" spacing={2.5}>*/}
    {/*  <CardMedia component="img" image={avatar} />*/}
    {/*  <Stack alignItems="center">*/}
    {/*    <Typography variant="h5">Need Support?</Typography>*/}
    {/*    <Typography variant="h6" color="secondary">*/}
    {/*      1 Day Response Time*/}
    {/*    </Typography>*/}
    {/*  </Stack>*/}
    {/*  <AnimateButton>*/}
    {/*    <Button*/}
    {/*      variant="shadow"*/}
    {/*      size="small"*/}
    {/*      component={Link}*/}
    {/*      href="https://phoenixcoded.authordesk.app/"*/}
    {/*      target="_blank"*/}
    {/*    >*/}
    {/*      Get Support*/}
    {/*    </Button>*/}
    {/*  </AnimateButton>*/}
    {/*</Stack>*/}
  </Card>
)

export default NavCard
