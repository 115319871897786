import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import { DRAWER_WIDTH } from 'config'
import { Context as LayoutContext } from '../Provider'
import Header from './Header'
import Content from './Content'
import * as Styled from './index.styles'

const Drawer = () => {
  const theme = useTheme()
  const downLG = useMediaQuery(theme.breakpoints.down('lg'))
  const { drawerOpen, toggleDrawer } = useContext(LayoutContext)

  /** @var {ReactNode} header */
  const header = useMemo(() => <Header open={drawerOpen} />, [drawerOpen])
  /** @var {ReactNode} content */
  const content = useMemo(() => <Content />, [])

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }} aria-label="mailbox folders">
      {!downLG ? (
        <Styled.MiniDrawer variant="permanent" open={drawerOpen}>
          {header}
          {content}
        </Styled.MiniDrawer>
      ) : (
        <MuiDrawer
          container={document.body}
          variant="temporary"
          open={drawerOpen}
          onClose={toggleDrawer}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
              borderRight: `1px solid ${theme.palette.divider}`,
              backgroundImage: 'none',
              boxShadow: 'inherit',
            },
          }}
        >
          {header}
          {content}
        </MuiDrawer>
      )}
    </Box>
  )
}

Drawer.propTypes = { window: PropTypes.func }

export default Drawer
