import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectAuth } from '../store/auth'

export default function useAuth() {
  const { user, loading } = useSelector(selectAuth)

  const hasRole = useCallback(role => user?.roles.includes(role), [user])

  const can = useCallback(role => user?.permissions.includes(role), [user])

  return {
    loading,
    user,
    isLoggedIn: !!user,
    hasRole,
    can,
  }
}
