// ==============================|| THEME CONSTANT ||============================== //

export const APP_DEFAULT_PATH = '/'
export const HORIZONTAL_MAX_ITEM = 7
export const DRAWER_WIDTH = 280
export const MINI_DRAWER_WIDTH = 90
export const HEADER_HEIGHT = 74

export const ThemeMode = {
  AUTO: 'auto',
  LIGHT: 'light',
  DARK: 'dark',
}

// ==============================|| THEME CONFIG ||============================== //
const config = {
  fontFamily: `Inter var`,
  i18n: 'en',
  menuOrientation: 'vertical',
  menuCaption: true,
  miniDrawer: false,
  container: false,
  mode: ThemeMode.LIGHT,
  presetColor: 'default',
  themeDirection: 'ltr',
  themeContrast: false,
}

export default config
