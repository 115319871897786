import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ButtonBase from '@mui/material/ButtonBase'
import LogoMain from './Logo'
import LogoIcon from './LogoIcon'
import useAuth from 'hooks/useAuth'
import { APP_DEFAULT_PATH } from 'config'

const Logo = ({ isIcon, sx, to }) => {
  const { isLoggedIn } = useAuth()

  const props = useMemo(() => {
    const buttonProps = { disableRipple: true, sx }

    if (isLoggedIn) {
      buttonProps.component = Link
      buttonProps.to = to ?? APP_DEFAULT_PATH
    }

    return buttonProps
  }, [isLoggedIn, sx])

  // noinspection JSValidateTypes
  return (
    <ButtonBase disableRipple {...props}>
      {isIcon ? <LogoIcon /> : <LogoMain />}
    </ButtonBase>
  )
}

Logo.propTypes = { isIcon: PropTypes.bool, sx: PropTypes.any, to: PropTypes.string }

export default Logo
