import { useState, useContext, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import List from '@mui/material/List'
import Menu from '@mui/material/Menu'
import Paper from '@mui/material/Paper'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Dot from 'components/@extended/Dot'
import IconButton from 'components/@extended/IconButton'
import SimpleBar from 'components/SimpleBar'
import Transitions from 'components/@extended/Transitions'
import NavItem from '../NavItem'
import { ThemeMode } from 'config'
import useConfig from 'hooks/useConfig'
import { ArrowDown2, ArrowUp2, ArrowRight2, Copy } from 'iconsax-react'
import { Context as LayoutContext } from 'layout/Provider'
import { useNav } from '../Provider'
import NavIcon from '../NavIcon'
import * as Styled from './index.styles'

const NavCollapse = ({ item, level = 1 }) => {
  const theme = useTheme()
  const navigation = useNavigate()
  const { drawerOpen } = useContext(LayoutContext)
  const { mode } = useConfig()
  const { isSelected } = useNav(item)
  const [popperAnchorEl, setPopperAnchorEl] = useState(null)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)

  const isPopperOpen = Boolean(popperAnchorEl)
  const isMenuOpen = Boolean(menuAnchorEl)

  const open = useCallback(
    event => {
      if (item.url) {
        navigation(item.url)
      }

      if (item.isDropdown) {
        setMenuAnchorEl(event.currentTarget)
      } else {
        setPopperAnchorEl(event.currentTarget)
      }
    },
    [item],
  )

  const close = useCallback(() => {
    setMenuAnchorEl(null)
    setPopperAnchorEl(null)
  }, [])

  const items = useMemo(
    () =>
      item.children?.reduce((acc, child) => {
        if (child.type === 'collapse') {
          acc.push(<NavCollapse key={child.id} item={child} level={level + 1} />)
        }

        if (child.type === 'item') {
          acc.push(<NavItem key={child.id} item={child} level={level + 1} />)
        }

        return acc
      }, []),
    [item, level],
  )

  const borderIcon = level === 1 ? <Copy variant="Bulk" size={drawerOpen ? 22 : 24} /> : null
  const Icon = item.icon ? <NavIcon icon={item.icon} size={drawerOpen ? 22 : 24} /> : borderIcon
  const textColor =
    mode === ThemeMode.DARK ? theme.palette.secondary[400] : theme.palette.secondary.main
  const iconSelectedColor =
    mode === ThemeMode.DARK && drawerOpen ? theme.palette.text.primary : theme.palette.primary.main

  return (
    <>
      <ListItemButton
        id={`${item.id}-button`}
        selected={isSelected}
        {...(!drawerOpen && {
          onMouseEnter: open,
          onMouseLeave: close,
        })}
        onClick={open}
        sx={{
          pl: drawerOpen ? `${level === 1 ? 20 : level * 20 - 10}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            mx: 1.25,
            my: 0.5,
            borderRadius: 1,
            '&:hover': { bgcolor: mode === ThemeMode.DARK ? 'divider' : 'secondary.200' },
            '&.Mui-selected': { color: iconSelectedColor },
          }),
          ...(!drawerOpen && {
            px: 2.75,
            justifyContent: 'center',
            '&:hover': { bgcolor: 'transparent' },
            '&.Mui-selected': {
              '&:hover': { bgcolor: 'transparent' },
              bgcolor: 'transparent',
            },
          }),
        }}
        {...(drawerOpen &&
          item.isDropdown && {
            'aria-controls': isMenuOpen ? `${item.id}-menu` : undefined,
            'aria-haspopup': true,
            'aria-expanded': isMenuOpen ? 'true' : undefined,
          })}
      >
        {Icon && (
          <ListItemIcon
            onClick={open}
            sx={{
              minWidth: 38,
              color: isSelected ? 'primary.main' : textColor,
              ...(!drawerOpen && {
                borderRadius: 1,
                width: 46,
                height: 46,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.200',
                },
              }),
              ...(!drawerOpen &&
                isSelected && {
                  bgcolor: mode === ThemeMode.DARK ? 'secondary.100' : 'primary.lighter',
                  '&:hover': {
                    bgcolor: mode === ThemeMode.DARK ? 'secondary.200' : 'primary.lighter',
                  },
                }),
            }}
          >
            {Icon}
          </ListItemIcon>
        )}

        {!Icon && drawerOpen && (
          <ListItemIcon sx={{ minWidth: 30 }}>
            <Dot size={isSelected ? 6 : 5} color={isSelected ? 'primary' : 'secondary'} />
          </ListItemIcon>
        )}

        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                color={isSelected ? 'primary' : textColor}
                sx={{ fontWeight: isSelected ? 500 : 400 }}
              >
                {item.title}
              </Typography>
            }
            secondary={
              item.caption && (
                <Typography variant="caption" color="secondary">
                  {item.caption}
                </Typography>
              )
            }
          />
        )}

        {(drawerOpen || (!drawerOpen && level !== 1)) &&
          (item.url ? (
            <IconButton
              onClick={event => {
                event.stopPropagation()
                open(event, false)
              }}
              color="secondary"
              variant="outlined"
              sx={{
                width: 20,
                height: 20,
                mr: '-5px !important',
                p: 0.25,
                color: 'secondary.dark',
                borderColor: open ? 'primary.light' : 'secondary.light',
                '&:hover': { borderColor: open ? 'primary.main' : 'secondary.main' },
              }}
            >
              {isPopperOpen || isMenuOpen ? (
                <>
                  {isPopperOpen ? (
                    <ArrowRight2 size={12} color={textColor} style={{ marginLeft: 1 }} />
                  ) : (
                    <ArrowUp2 size={12} color={textColor} style={{ marginLeft: 1 }} />
                  )}
                </>
              ) : (
                <ArrowDown2 size={12} color={textColor} style={{ marginLeft: 1 }} />
              )}
            </IconButton>
          ) : (
            <>
              {isPopperOpen || isMenuOpen ? (
                <>
                  {isPopperOpen ? (
                    <ArrowRight2 size={12} color={textColor} style={{ marginLeft: 1 }} />
                  ) : (
                    <ArrowUp2 size={12} color={textColor} style={{ marginLeft: 1 }} />
                  )}
                </>
              ) : (
                <ArrowDown2 size={12} color={textColor} style={{ marginLeft: 1 }} />
              )}
            </>
          ))}

        {!drawerOpen && (
          <Styled.Popper
            open={isPopperOpen}
            anchorEl={popperAnchorEl}
            placement="right-start"
            style={{ zIndex: 2001 }}
            popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [-12, 1] } }] }}
          >
            {({ TransitionProps }) => (
              <Transitions in={isPopperOpen} {...TransitionProps}>
                <Paper
                  sx={{
                    overflow: 'hidden',
                    mt: 1.5,
                    boxShadow: theme.customShadows.z1,
                    backgroundImage: 'none',
                    border: '1px solid ',
                    borderColor: 'divider',
                  }}
                >
                  <ClickAwayListener onClickAway={close}>
                    <>
                      <SimpleBar
                        sx={{
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          maxHeight: 'calc(100vh - 170px)',
                        }}
                      >
                        {items}
                      </SimpleBar>
                    </>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </Styled.Popper>
        )}
      </ListItemButton>
      {drawerOpen && !item?.isDropdown && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>{items}</List>
        </Collapse>
      )}
      {drawerOpen && item?.isDropdown && (
        <Menu
          id={`${item.id}-menu`}
          aria-labelledby={`${item.id}-button`}
          anchorEl={menuAnchorEl}
          open={isMenuOpen}
          onClose={close}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ '& .MuiPaper-root': { boxShadow: theme.shadows[2] } }}
        >
          {items}
        </Menu>
      )}
    </>
  )
}

NavCollapse.propTypes = {
  item: PropTypes.shape({
    isDropdown: PropTypes.bool,
  }),
  level: PropTypes.number,
}

export default NavCollapse
