import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from 'hooks/useAuth'
import { getMe } from 'store/auth'
import Loader from '../components/Loader'

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const dispatch = useDispatch()
  const { isLoggedIn, loading } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isLoggedIn) {
      // noinspection JSUnresolvedReference
      dispatch(getMe()).catch(() =>
        navigate('/login', {
          state: {
            from: location.pathname,
          },
          replace: true,
        }),
      )
    }
  }, [isLoggedIn, navigate, location, dispatch])

  return loading ? <Loader /> : children
}

AuthGuard.propTypes = { children: PropTypes.node }

export default AuthGuard
