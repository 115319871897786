import { memo, useContext } from 'react'
import PropTypes from 'prop-types'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useTheme } from '@mui/material/styles'
import { ThemeMode } from 'config'
import useConfig from 'hooks/useConfig'
import { Context as LayoutContext } from 'layout/Provider'

const NavIcon = ({ Icon, size, simple = false, isSelected = false, isRoot = false, ...rest }) => {
  const theme = useTheme()
  const { drawerOpen } = useContext(LayoutContext)
  const { mode } = useConfig()

  if (!Icon) return null

  const icon = <Icon variant="Bulk" size={size} {...rest} />

  if (simple) {
    return icon
  }

  const selectedColor =
    mode === ThemeMode.DARK && drawerOpen ? theme.palette.text.primary : theme.palette.primary.main
  const color =
    mode === ThemeMode.DARK ? theme.palette.secondary[400] : theme.palette.secondary.main

  return (
    <ListItemIcon
      sx={{
        minWidth: 38,
        color: isSelected ? selectedColor : color,
        ...(!drawerOpen &&
          isRoot && {
            borderRadius: 1,
            width: 46,
            height: 46,
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              bgcolor: mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.200',
            },
          }),
        ...(!drawerOpen &&
          isSelected && {
            bgcolor: mode === ThemeMode.DARK ? 'secondary.100' : 'primary.lighter',
            '&:hover': {
              bgcolor: mode === ThemeMode.DARK ? 'secondary.200' : 'primary.lighter',
            },
          }),
      }}
    >
      {icon}
    </ListItemIcon>
  )
}

NavIcon.propTypes = {
  Icon: PropTypes.func,
  size: PropTypes.number,
  isSelected: PropTypes.bool,
  isRoot: PropTypes.bool,
  simple: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
}

export default memo(NavIcon)
