import { useEffect, useMemo } from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { createStore } from './store'
import Theme from './theme'
import router from './routes'
import Notifications from './components/Notifications'

const App = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const store = useMemo(() => createStore(), [])

  return (
    <div className="App">
      <Provider store={store}>
        <Theme>
          <Notifications>
            <RouterProvider router={router} />
          </Notifications>
        </Theme>
      </Provider>
    </div>
  )
}

export default App
