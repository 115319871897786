import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { HEADER_HEIGHT, ThemeMode } from 'config'
import useConfig from 'hooks/useConfig'
import Palette from './palette'
import Typography from './typography'
import CustomShadows from './shadows'
import componentsOverride from './overrides'

const getWindowScheme = () => {
  const mq = window.matchMedia('(prefers-color-scheme: dark)')

  return mq.matches
}

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

const Theme = ({ children }) => {
  const { themeDirection, mode, presetColor, fontFamily, themeContrast } = useConfig()

  let themeMode = mode
  if (themeMode === ThemeMode.AUTO) {
    const autoMode = getWindowScheme()
    if (autoMode) {
      themeMode = ThemeMode.DARK
    } else {
      themeMode = ThemeMode.LIGHT
    }
  }

  const theme = useMemo(
    () => Palette(themeMode, presetColor, themeContrast),
    [themeMode, presetColor, themeContrast],
  )

  const themeTypography = useMemo(
    () => Typography(fontFamily),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fontFamily],
  )
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme])

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440,
        },
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: HEADER_HEIGHT,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
      shape: {
        borderRadius: 8,
      },
      customShadows: themeCustomShadows,
      typography: themeTypography,
    }),
    [themeDirection, theme, themeTypography, themeCustomShadows],
  )

  const themes = createTheme(themeOptions)
  themes.components = componentsOverride(themes)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

Theme.propTypes = { children: PropTypes.node }

export default Theme
