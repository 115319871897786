import { Security, User } from 'iconsax-react'

export const MENU = [
  // {
  //   id: 'root_nav_item',
  //   icon: Security,
  //   title: 'Root Nav Item',
  //   type: 'item',
  //   url: '/users',
  //   target: false,
  // },
  {
    id: 'access',
    title: 'Access',
    type: 'group',
    children: [
      {
        id: 'root_nav_item_in_group',
        title: 'Root Nav Item In Group',
        type: 'item',
        url: '/users',
        icon: Security,
        target: false,
      },
      {
        id: 'authentication',
        title: 'Authentication',
        type: 'collapse',
        icon: Security,
        children: [
          {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: User,
            children: [
              {
                id: 'list',
                title: 'List',
                type: 'item',
                url: '/users',
                target: false,
              },
            ],
          },
        ],
      },
    ],
  },
]
