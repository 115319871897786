import { enqueueSnackbar } from 'notistack'
import { apiCallFailed } from '../api'
import { logoutSucceeded } from '../auth'

const ignoreCodes = [422, 409, 403]

export const apiError =
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type !== apiCallFailed.type) return next(action)

    const error = action.payload

    if (error.response) {
      if (error.response.status === 401) {
        dispatch(logoutSucceeded())
      } else if (!ignoreCodes.includes(error.response.status)) {
        enqueueSnackbar(error.response.data.message || error.message, { variant: 'error' })
      }
    }
  }

export default apiError
