// noinspection JSValidateTypes

import { memo, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Dot from 'components/@extended/Dot'
import { Context as LayoutContext } from 'layout/Provider'
import useConfig from 'hooks/useConfig'
import { ThemeMode } from 'config'
import { useNav } from '../Provider'
import NavIcon from '../NavIcon'
import Action from './Action'

const ICON_SELECTED_COLOR = 'primary.main'

const NavItem = ({ item, floor = 0 }) => {
  const { drawerOpen, closeDrawer } = useContext(LayoutContext)
  const { mode } = useConfig()
  const { isSelected } = useNav(item)
  const level = floor || 1

  const target = item.target ? '_blank' : '_self'

  const color = mode === ThemeMode.DARK ? 'secondary.400' : 'secondary.main'

  return (
    <Box sx={{ position: 'relative' }}>
      <ListItemButton
        component={Link}
        to={item.url}
        target={target}
        disabled={item.disabled}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          pl: drawerOpen ? `${level * 20}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            '&:hover': { bgcolor: 'transparent' },
            '&.Mui-selected': { '&:hover': { bgcolor: 'transparent' }, bgcolor: 'transparent' },
          }),
          ...(drawerOpen &&
            level === 1 && {
              mx: 1.25,
              my: 0.5,
              borderRadius: 1,
              '&:hover': { bgcolor: mode === ThemeMode.DARK ? 'divider' : 'secondary.200' },
              '&.Mui-selected': {
                color: ICON_SELECTED_COLOR,
                '&:hover': { color: ICON_SELECTED_COLOR },
              },
            }),
          ...(!drawerOpen && {
            px: 2.75,
            justifyContent: 'center',
            '&:hover': { bgcolor: 'transparent' },
            '&.Mui-selected': { '&:hover': { bgcolor: 'transparent' }, bgcolor: 'transparent' },
          }),
        }}
        onClick={closeDrawer}
      >
        <NavIcon
          Icon={item.icon}
          size={drawerOpen ? 20 : 22}
          style={{ ...(!floor && { fontSize: 20, stroke: '1.5' }) }}
          isRoot
          isSelected={isSelected}
        />

        {!item.icon && drawerOpen && (
          <ListItemIcon sx={{ minWidth: 30 }}>
            <Dot size={isSelected ? 6 : 5} color={isSelected ? 'primary' : 'secondary'} />
          </ListItemIcon>
        )}

        {(drawerOpen || (!drawerOpen && level > 1)) && (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? ICON_SELECTED_COLOR : color,
                  fontWeight: isSelected ? 500 : 400,
                }}
              >
                {item.title}
              </Typography>
            }
          />
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>

      {(drawerOpen || (!drawerOpen && level !== 1)) &&
        item?.actions?.map((action, index) => (
          <Action key={index} action={action} isSelected={isSelected} />
        ))}
    </Box>
  )
}

NavItem.propTypes = {
  item: PropTypes.shape({
    target: PropTypes.bool,
    icon: PropTypes.func,
    url: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['function', 'link']),
        icon: PropTypes.func.isRequired,
        function: PropTypes.func,
        url: PropTypes.string,
        target: PropTypes.bool,
      }),
    ),
    chip: PropTypes.shape({
      color: PropTypes.oneOf([
        'default',
        'primary',
        'secondary',
        'error',
        'info',
        'success',
        'warning',
      ]),
      variant: PropTypes.oneOf(['filled', 'outlined']),
      size: PropTypes.oneOf(['small', 'medium']),
      label: PropTypes.string,
      avatar: PropTypes.node,
    }),
  }).isRequired,
  floor: PropTypes.number,
}

export default memo(NavItem)
