import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import * as Styled from './index.styles'

// noinspection JSValidateTypes
const IconButton = (
  { variant = 'text', shape = 'square', color = 'primary', children, ...rest },
  ref,
) => (
  <Styled.IconButton ref={ref} variant={variant} shape={shape} color={color} {...rest}>
    {children}
  </Styled.IconButton>
)

IconButton.displayName = 'IconButton'

export default forwardRef(IconButton)

IconButton.propTypes = {
  variant: PropTypes.string,
  shape: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  others: PropTypes.any,
}
