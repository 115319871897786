import { createContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import useLocalStorage from 'hooks/useLocalStorage'

// initial state
const initialState = {
  ...config,
  onChangeMode: () => {},
}

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

export const ConfigContext = createContext(initialState)

const ConfigProvider = ({ children }) => {
  const { value: config, update } = useLocalStorage('app-config', initialState)

  // const onChangeLocalization = (lang) => {
  //   setConfig({
  //     ...config,
  //     i18n: lang
  //   });
  // };

  const value = useMemo(
    () => ({
      ...config,
      onChangeMode: mode => {
        update({
          ...config,
          mode,
        })
      },
    }),
    [config, update],
  )

  // noinspection JSValidateTypes
  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
}

ConfigProvider.propTypes = { children: PropTypes.node }

export default ConfigProvider
