import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from 'config'

export const AppBar = styled(MuiAppBar, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(!open && {
      width: `calc(100% - ${MINI_DRAWER_WIDTH})`,
    }),
    ...(open && {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }),
)
