import { createContext, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

export const Context = createContext({
  drawerOpen: false,
  toggleDrawer: () => {},
  closeDrawer: () => {},
  openDrawer: () => {},
})

const Provider = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = useCallback(() => {
    setDrawerOpen(current => !current)
  }, [])

  const closeDrawer = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const openDrawer = useCallback(() => {
    setDrawerOpen(true)
  }, [])

  const value = useMemo(
    () => ({
      drawerOpen,
      toggleDrawer,
      closeDrawer,
      openDrawer,
    }),
    [drawerOpen, toggleDrawer, openDrawer, closeDrawer],
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

Provider.propTypes = {
  children: PropTypes.node,
}

export default Provider
