import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

export const Header = styled(Box, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: open ? 'flex-start' : 'center',
    paddingLeft: theme.spacing(open ? 3 : 0),
  }),
)
