import SimpleBar from 'simplebar-react'
import { alpha, styled } from '@mui/material/styles'
import { BrowserView as View } from 'react-device-detect'

export const BrowserView = styled(View)({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
})

export const Bar = styled(SimpleBar)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': { backgroundColor: alpha(theme.palette.secondary.main, 0.25) },
    '&.simplebar-visible:before': { opacity: 1 },
  },
  '& .simplebar-track.simplebar-vertical': { width: 10 },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': { height: 6 },
  '& .simplebar-mask': { zIndex: 'inherit' },
}))
