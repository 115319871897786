import { Fragment, memo, useContext } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { Context as LayoutContext } from 'layout/Provider'
import { MENU } from 'layout/config'
import NavItem from './NavItem'
import NavGroup from './NavGroup'
import NavProvider from './Provider'

const Navigation = () => {
  const { drawerOpen } = useContext(LayoutContext)

  const nav = MENU.map(item => {
    switch (item.type) {
      case 'group':
        if (item.url) {
          return (
            <Fragment key={item.id}>
              <Divider sx={{ my: 0.5 }} />
              <NavItem item={item} />
            </Fragment>
          )
        }

        return <NavGroup key={item.id} item={item} />
      case 'item':
        return <NavItem key={item.id} item={item} />
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        )
    }
  })

  return (
    <Box
      sx={{
        pt: drawerOpen ? 2 : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: 'block',
      }}
    >
      <NavProvider>{nav}</NavProvider>
    </Box>
  )
}

export default memo(Navigation)
