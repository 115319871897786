import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { Code, Copy } from 'iconsax-react'
import { ThemeMode } from 'config'
import IconButton from 'components/@extended/IconButton'

const Highlighter = ({ codeString, codeHighlight = false }) => {
  const theme = useTheme()
  const [highlight, setHighlight] = useState(codeHighlight)

  const toggleHighlight = useCallback(() => setHighlight(curr => !curr), [])

  return (
    <>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ display: 'flex' }}>
          <CopyToClipboard text={codeString}>
            <Tooltip title="Copy the source" placement="top-end">
              <IconButton color="secondary" size="small">
                <Copy />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
          <Tooltip title="Show the source" placement="top-end">
            <IconButton
              sx={{ fontSize: '0.875rem' }}
              size="small"
              color={highlight ? 'primary' : 'secondary'}
              onClick={toggleHighlight}
            >
              <Code />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
      <Collapse in={highlight}>
        {highlight && (
          <SyntaxHighlighter
            language="javascript"
            showLineNumbers
            style={theme.palette.mode === ThemeMode.DARK ? a11yLight : a11yDark}
          >
            {codeString}
          </SyntaxHighlighter>
        )}
      </Collapse>
    </>
  )
}

Highlighter.propTypes = { codeString: PropTypes.string, codeHighlight: PropTypes.bool }

export default Highlighter
