import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { MobileView } from 'react-device-detect'
import * as Styled from './index.styles'

// ==============================|| SIMPLE SCROLL BAR  ||============================== //

const SimpleBar = ({ children, sx, ...other }) => (
  <>
    <Styled.BrowserView>
      <Styled.Bar clickOnTrack={false} sx={sx} {...other}>
        {children}
      </Styled.Bar>
    </Styled.BrowserView>
    <MobileView>
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    </MobileView>
  </>
)

SimpleBar.propTypes = { children: PropTypes.node, sx: PropTypes.any, other: PropTypes.any }

export default SimpleBar
