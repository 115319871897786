import PropTypes from 'prop-types'
import * as Styled from './index.styles'

const Avatar = ({ children, color = 'primary', type, size = 'md', ...others }) => (
  <Styled.Avatar color={color} type={type} size={size} {...others}>
    {children}
  </Styled.Avatar>
)

Avatar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  color: PropTypes.string,
  type: PropTypes.any,
  size: PropTypes.string,
  others: PropTypes.any,
}

export default Avatar
