import { useContext, memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import useConfig from 'hooks/useConfig'
import { ThemeMode } from 'config'
import { Context as LayoutContext } from 'layout/Provider'
import NavItem from '../NavItem'
import NavCollapse from '../NavCollapse'

const NavGroup = ({ item }) => {
  const theme = useTheme()
  const { menuCaption } = useConfig()
  const { drawerOpen } = useContext(LayoutContext)

  const items = useMemo(
    () =>
      item.children?.reduce((acc, child) => {
        if (child.type === 'collapse') {
          // noinspection JSValidateTypes
          acc.push(<NavCollapse key={child.id} item={child} />)
        }

        if (child.type === 'item') {
          acc.push(<NavItem key={child.id} item={child} />)
        }

        return acc
      }, []),
    [item],
  )

  return (
    <List
      subheader={
        item.title && drawerOpen ? (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography
              variant="h5"
              color={theme.palette.mode === ThemeMode.DARK ? 'text.secondary' : 'secondary.dark'}
              sx={{ textTransform: 'uppercase', fontSize: '0.688rem' }}
            >
              {item.title}
            </Typography>
            {item.caption && (
              <Typography variant="caption" color="secondary">
                {item.caption}
              </Typography>
            )}
          </Box>
        ) : (
          <Divider sx={{ my: 0.5 }} />
        )
      }
      sx={{ mt: drawerOpen && menuCaption && item.title ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {items}
    </List>
  )
}

NavGroup.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.oneOf(['collapse', 'item']),
      }),
    ),
  }),
}

export default memo(NavGroup)
