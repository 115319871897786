import LinearProgress from '@mui/material/LinearProgress'
import * as Styled from './index.styles'

const Loader = () => (
  <Styled.Loader>
    <LinearProgress color="primary" sx={{ height: 2 }} />
  </Styled.Loader>
)

export default Loader
