import { combineReducers, configureStore } from '@reduxjs/toolkit'
import api from './middleware/api'
import apiError from './middleware/apiError'
import auth from './auth'

export const createStore = () =>
  configureStore({
    reducer: combineReducers({ auth }),
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      api,
      apiError,
    ],
  })
