import { styled } from '@mui/material/styles'
import { SnackbarProvider as BaseSnackbarProvider } from 'notistack'

export const SnackbarProvider = styled(BaseSnackbarProvider)(({ theme }) => ({
  '&.notistack-MuiContent-default': { backgroundColor: theme.palette.primary.main },
  '&.notistack-MuiContent-error': { backgroundColor: theme.palette.error.main },
  '&.notistack-MuiContent-success': { backgroundColor: theme.palette.success.main },
  '&.notistack-MuiContent-info': { backgroundColor: theme.palette.info.main },
  '&.notistack-MuiContent-warning': { backgroundColor: theme.palette.warning.main },
}))
