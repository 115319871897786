import PropTypes from 'prop-types'
import { CloseCircle, InfoCircle, TickCircle, Warning2 } from 'iconsax-react'
import * as Styled from './index.styles'

const options = {
  action: false,
  open: false,
  message: 'Note archived',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  variant: 'default',
  alert: {
    color: 'primary',
    variant: 'filled',
  },
  transition: 'Fade',
  close: false,
  actionButton: false,
  maxStack: 3,
  dense: false,
  iconVariant: 'usedefault',
}

const iconSX = { marginRight: 8, fontSize: '1.15rem' }

const Notifications = ({ children, iconVariant = options.iconVariant }) => (
  <Styled.SnackbarProvider
    maxSnack={options.maxStack}
    dense={options.dense}
    iconVariant={
      iconVariant === 'useemojis'
        ? {
            success: <TickCircle style={iconSX} />,
            error: <CloseCircle style={iconSX} />,
            warning: <Warning2 style={iconSX} />,
            info: <InfoCircle style={iconSX} />,
          }
        : undefined
    }
    hideIconVariant={iconVariant === 'hide'}
  >
    {children}
  </Styled.SnackbarProvider>
)

Notifications.propTypes = {
  children: PropTypes.node,
  iconVariant: PropTypes.oneOf(['hide', 'useemojis', 'usedefault']),
}

export default Notifications
