import React from 'react'
import ReactDOM from 'react-dom/client'
import ConfigProvider from 'config/ConfigProvider'

import App from './App'
import reportWebVitals from './reportWebVitals'

import 'assets/fonts/inter/inter.css'

import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

// noinspection JSCheckFunctionSignatures
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ConfigProvider>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
